import React from 'react'
import PropTypes from 'prop-types'
import { MapPinIcon } from './icons'

const DestinationTag = ({ destination }) => {
  return (
    <>
      {
        destination
        && <div className="flex items-center gap-14">
          <div className="h-30 w-30 flex justify-center items-center bg-grey-150 rounded-full"><MapPinIcon /></div>
          <div>
            <p className="text-14 text-primary-fresh">{destination?.region}</p>
            <p className="text-12 text-grey-500">{destination?.country}</p>
          </div>
        </div>
      }
    </>

  )
}

DestinationTag.propTypes = {
  destination: PropTypes.object,
}

export default DestinationTag
