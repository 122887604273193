import classNames from 'classnames'
import CarouselImagesSmall from 'components/carousels/Cards/home/CarouselImagesSmall'
import Pill from 'components/home/Pill'
import Headers from 'components/home/common/typography/Headers'
import { MapPinIcon } from 'components/icons'

export type CarouselCardProps = {
  mainImage?: {
    media?: string | null
    alt_text: string
  }
  images?: {
    media?: string | null
    alt_text: string
  }[]
  heading: string
  subheading?: string | null
  imageTagOne?: string | null
  imageTagTwo?: string | null
  pills?: string[]
  destination?: {
    country?: string
    region?: string
  }
  className?: {
    heading?: string
    subheading?: string
    image?: {
      tagOne?: string
      tagTwo?: string
    }
  }
  hoverEffects?: boolean
}

const CarouselCard = ({
  mainImage,
  images,
  imageTagOne,
  imageTagTwo,
  heading,
  subheading,
  pills,
  destination,
  className,
  hoverEffects = false,
}: CarouselCardProps) => {
  return (
    <div className="flex flex-col group">
      <div className="relative aspect-h-5 aspect-w-7">
        <CarouselImagesSmall
          mainImage={{
            media: mainImage?.media,
            alt_text: mainImage?.alt_text ?? heading,
          }}
          images={images?.map(image => ({
            media: image.media,
            alt_text: image?.alt_text,
          })) ?? []}
        />
      </div>

      {(imageTagOne || imageTagTwo) && (
        <div className="flex items-start justify-between mt-[6px] text-12 lg:text-14 leading-20">
          {
            imageTagOne && (
              <p className={classNames(
                'text-primary-fresh',
                className?.image?.tagOne,
              )}>
                {imageTagOne}
              </p>
            )
          }

          {
            imageTagTwo && (
              <p className={classNames(
                'text-grey-650',
                className?.image?.tagTwo,
              )}>
                {imageTagTwo}
              </p>
            )
          }
        </div>
      )}

      {
        heading && (
          <Headers.BaseHeader className={classNames(
            'transition-colors mt-12 text-grey-900 text-24 leading-30',
            className?.heading,
            {
              'group-hover:text-primary-fresh': hoverEffects,
            },
          )}>
            {heading}
          </Headers.BaseHeader>
        )
      }

      {
        subheading && (
          <p
            className={classNames(
              className?.subheading,
              'mt-[6px] text-13 text-grey-800 leading-20',
            )}
          >
            {subheading}
          </p>
        )
      }

      {
        pills && pills.map((pill, index) => (
          <Pill key={index} className="mt-8" variant="grey-sans" size="8">{pill}</Pill>
        ))
      }

      {
        destination && (
          <div className="flex items-center mt-20 gap-14">
            <MapPinIcon className="px-10 py-8 rounded-full bg-grey-150 h-30 w-30 text-grey-1100 text-13" />
            <div className="flex flex-col text-13">
              {destination.country && <p className="text-primary-fresh leading-[15px]">{destination.country}</p>}
              {destination.region && <p className="text-grey-800 leading-[15px]">{destination.region}</p>}
            </div>
          </div>
        )
      }
    </div>
  )
}

export default CarouselCard
