import React, { useRef, useState } from 'react'
import placeholderImage from 'assets/images/placeholder.png'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import Image from 'components/Image'
import { getLoadedImagesArray, getNumberArray } from 'data/helpers/carousel'
import { useOnScreen } from 'data/hooks/useOnScreen'
import classNames from 'classnames'

type Props = {
  mainImage: {
    media?: string | null
    alt_text: string
  },
  images: {
    media?: string | null
    alt_text: string
  }[],
  variant?: 'none' | 'admin',
}

const CarouselImagesSmall = ({
  mainImage,
  images,
  variant = 'none',
}: Props) => {
  const componentRef = useRef<HTMLInputElement>(null)

  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  const buttonClasses = 'absolute top-1/2 cursor-pointer -mt-14'

  let prevButtonClasses = 'swiper-button-property-images-prev lg:opacity-0 lg:group-hover:opacity-100'
  let nextButtonClasses = 'swiper-button-property-images-next lg:opacity-0 lg:group-hover:opacity-100'

  const { initialLoad } = useOnScreen(componentRef)

  switch (variant) {
    case 'admin':
      prevButtonClasses = 'swiper-button-admin-property-images-prev'
      nextButtonClasses = 'swiper-button-admin-property-images-next'
  }

  const imagesEitherSide = 1
  const imagesLength = images.length + (mainImage ? 1 : 0)
  const numberArray = getNumberArray(imagesLength)
  const [loadedImages, setLoadedImages] = useState([...numberArray.slice(0, imagesEitherSide), ...numberArray.slice(-1)])

  return (
    <div className="w-full h-full" ref={componentRef}>
      {
        initialLoad
        && <>
          <Swiper
            modules={[Navigation]}
            className={classNames(
              'h-full rounded-10 group',
              'group-hover:brightness-90 transition-all',
            )}
            loop={true}
            slidesPerView={1}
            spaceBetween={20}
            enabled={images.length > 0}
            preloadImages={false}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onSwiper={(swiper: SwiperClass) => {
              setTimeout(() => {
                // Timeout until prevEl & nextEl refs are defined
                if (typeof(swiper.params.navigation) !== 'boolean') {
                  swiper.params.navigation!.prevEl = navigationPrevRef.current
                  swiper.params.navigation!.nextEl = navigationNextRef.current
                }

                // Re-initialise navigation
                swiper.navigation.destroy()
                swiper.navigation.init()
                swiper.navigation.update()
              })
            }}
            onSlideChange={(swiper: SwiperClass) => {
              if (loadedImages.length !== imagesLength) {
                setLoadedImages(getLoadedImagesArray(
                  swiper.realIndex,
                  imagesEitherSide,
                  imagesLength,
                  numberArray,
                  loadedImages,
                ))
              }
            }}
            observer={true}
          >
            {
              (mainImage.media || !mainImage.media && images.length === 0)
              && <SwiperSlide>
                <div className="relative w-full h-full rounded-10">
                  <Image
                    className="object-cover w-full h-full"
                    src={mainImage.media ?? placeholderImage}
                    alt={mainImage.alt_text}
                    layout="fill"
                    sizes="(max-width: 400px) 400px,
                          (max-width: 640px) 600px,
                          (max-width: 1024px) 480px,
                          400px"
                    priority
                  />
                </div>
              </SwiperSlide>
            }
            {
              images !== undefined && images.length > 0
                ? images.map((image, index) =>
                  <SwiperSlide key={index}>
                    <div className="relative w-full h-full rounded-10">
                      {loadedImages.includes(index)
                        && <Image
                          className="object-cover w-full h-full"
                          src={image.media ?? placeholderImage}
                          alt={image.alt_text}
                          layout="fill"
                          sizes="(max-width: 400px) 400px,
                                (max-width: 640px) 600px,
                                (max-width: 1024px) 480px,
                                400px"
                          priority
                        />
                      }
                    </div>
                  </SwiperSlide>,
                )
                : null
            }
            <div
              ref={navigationPrevRef}
              className={classNames(
                'transition-all lg:pointer-events-none',
                prevButtonClasses,
                buttonClasses,
                {
                  'opacity-0': images.length === 0,
                  'group-hover:pointer-events-auto': images.length > 0,
                },
              )}
              onClick={(e) => e.stopPropagation()}
            />
            <div
              ref={navigationNextRef}
              className={classNames(
                'transition-all lg:pointer-events-none',
                nextButtonClasses,
                buttonClasses,
                {
                  'opacity-0': images.length === 0,
                  'group-hover:pointer-events-auto': images.length > 0,
                },
              )}
              onClick={(e) => e.stopPropagation()}
            />
          </Swiper>
        </>
      }
    </div>

  )
}

export default CarouselImagesSmall
