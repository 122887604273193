import CarouselCard from 'components/service/CarouselCard'
import { RestArticle, RestArticlePage } from 'data/types/article'
import React, { useEffect } from 'react'
import he from 'he'
import Link from 'components/Link'
import { InfiniteData } from 'react-query'
import { useInView } from 'react-intersection-observer'
import { LoadingIcon } from 'components/icons'
import classNames from 'classnames'
import { ReactChild } from 'data/types/types'

interface MobileArticleListProps {
  articles?: InfiniteData<RestArticlePage>
  onLoadMore?: () => void
  loading?: boolean
  className?: {
    container?: string
  }
  button?: ReactChild
  scrollRef?: boolean,
}

const MobileArticleList = ({
  articles,
  onLoadMore,
  loading = false,
  className,
  button,
  scrollRef = true,
}: MobileArticleListProps) => {
  const { ref: infiniteScrollRef, inView } = useInView()

  useEffect(() => {
    if (inView) {
      onLoadMore?.()
    }
  }, [inView])

  return (
    <div className={classNames(
      'flex flex-col items-center gap-50',
      className?.container,
    )}>
      {articles?.pages.map((page) =>
        page.items.map((article: RestArticle) => (
          <Link href={`/magazine/${article.slug}`} key={article.id} className="max-w-[495px]">
            <CarouselCard
              hoverEffects
              heading={he.decode(article.title)}
              subheading={he.decode(article.excerpt)}
              imageTagOne={he.decode(article.categories[0].name).toUpperCase()}
              imageTagTwo={article.created_at}
              mainImage={{
                alt_text: article.thumbnail.alt_text ?? article.title,
                media: article.thumbnail.src,
              }}
              key={article.id}
              destination={article.destination}
              className={{
                heading: 'normal-case',
                subheading: '!text-grey-700 !mt-12',
                image: {
                  tagOne: '!text-grey-700',
                },
              }}
            />
          </Link>
        )),
      )}
      { loading && <LoadingIcon /> }

      { button }

      { scrollRef && <div ref={infiniteScrollRef} className="h-[1px] w-full inset-x-0" /> }
    </div>
  )
}

export default MobileArticleList
