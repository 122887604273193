import Link from 'components/Link'
import placeholderImage from 'assets/images/placeholder.png'
import DestinationTag from 'components/DestinationTag'
import Image from 'components/Image'
import { useSearchParams } from 'data/hooks/useSearchParams'
import { ReactChild } from 'data/types/types'
import { InfiniteData } from 'react-query'
import { RestArticlePage, RestArticlePagePaginated } from 'data/types/article'

interface ArticleListProps {
  articles?: InfiniteData<RestArticlePage> | RestArticlePagePaginated
  button: ReactChild
}

const ArticleList = ({
  articles,
  button,
}: ArticleListProps) => {
  const query = useSearchParams()

  return (
    <div>
      <div className="flex flex-col gap-75 mb-75">
        {
          articles && articles.pages && articles.pages.map((page) => {
            return page?.items.map((article) => {
              return (
                <div key={article.id} className="self-center mx-auto w-full max-w-[1000px]">
                  <Link href={`/magazine/${article.slug}?${query.params.toString()}`}>
                    <div className="flex flex-col items-center cursor-pointer lg:flex-row gap-15 lg:gap-50 group">
                      <div className="relative object-center w-full max-w-[495px] h-[226px] lg:h-[335px] rounded-4 flex-shrink-0">
                        <Image
                          src={article.thumbnail?.src ?? placeholderImage}
                          alt={article.thumbnail?.alt_text}
                          className="absolute inset-0 object-cover transition-all group-hover:brightness-90 rounded-4"
                          layout="fill"
                        />
                      </div>
                      <div className="max-w-[480px] w-full">
                        {
                          article.categories.length
                            ? <p className="mt-10 font-bold uppercase text-14 text-grey-750 tracking-1/2 leading-20 mb-15" dangerouslySetInnerHTML={{ __html: article.categories[0].name }} />
                            : null
                        }

                        <h5 className="font-serif font-bold text-26 lg:text-30 text-grey-900 group-hover:text-primary transition-all tracking-[-0.09px] leading-[32px] mb-20" dangerouslySetInnerHTML={{ __html: article.title }}></h5>
                        <div className="mb-20 text-14 text-grey-750 leading-22" dangerouslySetInnerHTML={{ __html: article.excerpt }}></div>
                        <div className="mb-[27px]">
                          <DestinationTag destination={article.destination} />
                        </div>
                        <p className="text-14 text-grey-500 leading-24 -tracking-1/2">
                          Published on {article.created_at}
                          <br />
                          By <Link className="underline" href={`/magazine/authors/${article.author.slug}`}>{article.author.name}</Link>
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })
          })
        }
      </div>
      {
        button
      }
    </div>
  )
}

export default ArticleList
